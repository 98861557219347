// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-pages-account-page-tsx": () => import("./../../src/components/pages/AccountPage.tsx" /* webpackChunkName: "component---src-components-pages-account-page-tsx" */),
  "component---src-components-pages-auth-forgot-password-page-tsx": () => import("./../../src/components/pages/auth/ForgotPasswordPage.tsx" /* webpackChunkName: "component---src-components-pages-auth-forgot-password-page-tsx" */),
  "component---src-components-pages-auth-login-page-tsx": () => import("./../../src/components/pages/auth/LoginPage.tsx" /* webpackChunkName: "component---src-components-pages-auth-login-page-tsx" */),
  "component---src-components-pages-download-tsx": () => import("./../../src/components/pages/Download.tsx" /* webpackChunkName: "component---src-components-pages-download-tsx" */),
  "component---src-components-pages-station-list-page-tsx": () => import("./../../src/components/pages/StationListPage.tsx" /* webpackChunkName: "component---src-components-pages-station-list-page-tsx" */),
  "component---src-components-pages-station-page-tsx": () => import("./../../src/components/pages/StationPage.tsx" /* webpackChunkName: "component---src-components-pages-station-page-tsx" */),
  "component---src-components-pages-user-list-page-tsx": () => import("./../../src/components/pages/UserListPage.tsx" /* webpackChunkName: "component---src-components-pages-user-list-page-tsx" */),
  "component---src-components-pages-user-page-tsx": () => import("./../../src/components/pages/UserPage.tsx" /* webpackChunkName: "component---src-components-pages-user-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-tsx": () => import("./../../src/pages/App.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */)
}

